@media only screen and (max-width: 800px) {
  .desktop-only {
    display: none;
  }

  .button {
    width: 100% !important;
    max-width: 290px !important;
  }

  .title {
    font-size: 33px;
  }

  .mobile-only {
    display: block;
  }

  .section-01 {
    padding-bottom: 100px;

    .author-image {
      width: 90%;
      margin: 0 auto;
    }


    .top-block {
      padding: 0;
    }

    .info-block {
      max-width: 100%;
      margin: 0;

      .top {
        width: 70px;
      }

      .bottom {
        width: 80px;
      }
    }

    .info-inner {
      text-align: center;
      font-size: 21px;
    }

    .main-title {
      text-align: center;
      font-size: 50px;
      line-height: 1;
      span {
        margin: 0;
        line-height: 1;
      }
    }

    .image-block {
      max-width: 400px;
      margin: 0 auto;
    }

    .date-list {
      flex-direction: row;
      flex-wrap: wrap;
      margin: 30px 0 10px;
      align-items: center;
      justify-content: center;

       > .item:first-child{
        width: 100%;
         text-align: center;
         margin: 0 auto 15px !important;
         display: flex;
         justify-content: center;
       }

      .item {
        width: 50%;
        margin: 0;
      }
    }

    .button {
      margin: 25px auto;
    }

    .bottom-block {
      width: 90%;
      background: #fae4ca;
      border-radius: 20px;
      max-width: 550px;
      background-size: cover;
      height: auto;
      padding: 30px 20px;
    }
  }

  .section-02 {
    padding: 150px 10px 50px;

    .list {
      flex-direction: column;
      align-items: center;

      .item {
        text-align: center;
        width: 100%;
        max-width: 375px;
        margin-bottom: 25px;
      }

      .item-title {
        font-size: 25px;
      }

      .item-icon {
        margin: 0 auto;
      }
    }

    .secondary-list {
      flex-direction: column;
      align-items: center;

      .item {
        text-align: center;
        width: 100%;
        max-width: 375px;
        margin-bottom: 25px;
      }

      .item-icon {
        margin: 0 auto 25px;
      }
    }
  }

  .lana-del-block {
    & {
      background: #f7dfc3;
      padding: 50px 30px;
      margin-bottom: 0;
    }

    .subtitle {
      max-width: 400px;
      font-size: 30px;
      margin: 0 auto 10px;
      text-align: center;
    }

    .quote {
      font-weight: 700;
      text-align: right;
    }

    .inner-info {
      max-width: 100%;

      &:after {
        display: none;
      }
    }

    .lana-img {
      position: static;
      width: 100%;
      max-width: 400px;
      margin: 10px auto;
    }

    .info {
      margin: 0 auto 20px;
      text-align: center;
    }

    .button {
      margin: 0 auto;
    }
  }

  .section-03 {
    padding: 50px 10px;

    .title {
      margin-bottom: 20px;
      text-align: center;
    }

    .list-info {
      background: none;
      flex-direction: column;
    }

    .info-column {
      width: 100%;
      padding-top: 30px;
      text-align: center;
      margin: 0 auto;

      p {
        margin-bottom: 30px;
      }
    }
  }

  .section-04 {
    .title {
      text-align: center;
    }

    .course-info {
      padding: 0 10px;
      flex-direction: column;
      align-items: center;

      .course-column.img {
        order: 2;
        margin-top: 20px;
      }
    }
  }

  .imagine-block  {
    & {
      background: #edf2f6;
    }

    .inner-block {
      padding: 50px 30px;
      margin-bottom: 0;
    }

    .cake {
      left: auto;
      right: 0;
      top: -30px;
    }

    .cake-2 {
      bottom: -30px;
      right: auto;
      left: 0;
    }

    .info-text {
      margin-left: auto;
      margin-right: auto;
    }

    .bottom-info {
      margin: 20px auto;
      text-align: center;
    }

    .button {
      margin: 10px auto;
    }

    .image-block {
      width: 100%;
      max-width: 400px;
      margin: 10px auto;
    }
  }

  .process-block {
    margin: 0 auto;

    .info-block {
      margin: 0 auto;
      padding-bottom: 50px;
    }

    .subtitle {
      font-size: 35px;
      text-align: center;
      line-height: 1.3;
      margin: 0 auto 10px;
    }

    .info-text {
      margin: -50px auto 20px;
      text-align: center;
    }

    .button {
      margin: 10px auto;
    }

    .image {
      margin: 0 auto;
      width: 90%;
    }
  }

  .section-05 {
    .title {
      text-align: center;
    }
  }

  .format-list {
    flex-direction: column;
    align-items: center;

    .format-item {
      text-align: center;
      width: 100%;
    }
  }

  .certificate-block {
    flex-direction: column;
    padding: 30px 20px;
    margin-bottom: 40px;

    .info-text {
      margin: 0 auto 20px;
      text-align: center;
    }

    .image {
      width: 90%;
      max-width: 380px;

    }
  }

  .about-course .course-column {
    width: 100%;
  }

  .about-course .cake-2 {
    display: none;
  }

  .section-06 {
      padding: 50px 20px;

    .title {
      text-align: center;
    }

    .master-class {
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
    }

    .master-item {
      width: 100%;
      max-width: 375px;
      text-align: center;
      margin-bottom: 30px;

      .master-title {
        margin-bottom: 5px;
      }
    }
  }

  .package-block {
    flex-direction: column;
    padding: 30px 30px 75px;
    text-align: center;

    .package-text {
      margin-bottom: 30px;
    }
  }

  .section-07 {
    margin-top: 35px;

    .title {
      text-align: center;
    }

    .subtitle {
      text-align: center;
    }
  }

  .lessons-list {
    flex-direction: column;
    align-items: center;


    .lessons-item {
      order: 0 !important;
      width: 100%;
    }
  }

  .bonus-block {
    padding: 0 20px;
  }

  .bonus-list {
    flex-direction: column;
    align-items: center;

    .bonus-column {
      margin-bottom: 30px;
    }
  }

  .section-08 {
    padding-bottom: 50px;
  }

  .section-09 {
    .title {
      text-align: center;
      margin-bottom: 30px;
    }

    .button {
      margin-top: 50px;
    }
  }

  .maker-image {
    width: 100%;
    height: auto;
  }

  .maker-item {
    width: 48%;
    margin-bottom: 20px;
    padding: 0;
    max-width: 100%;
  }

  .section-10 {
    .title {
      text-align: center;
      font-size: 30px;
    }

    .cake-1 {
      width: 60px;
      top: 0;
    }
  }

  .group-block {
    margin: 50px auto;
  }

  .section-11 {
    padding: 110px 10px 30px;

    .info-about {
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }

    .text {
      margin-top: 20px;
    }

    .author-image {
      width: 90%;
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .section-12 {
    padding: 50px 15px 0;
  }

  .work-items {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .group-block .inner-block .g-title {
    font-size: 23px;
  }

  .group-block {
    background: #edf2f6;
    text-align: center;
    padding: 30px;

    .inner-block {
      max-width: 100%;
      width: 100%;

      .button {
        margin: 10px auto;
      }

      .image {
        width: 90%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .section-11 .big-title {
    font-size: 35px;
  }

  .section-11 .little-title {
    font-size: 20px;
  }

  .modal-open {
    .close-btn {
      display: block;
    }
    .modal-inner {
      width: 80%;
      top: 0;
      left: 0;
    }
  }
  .close-btn {
    display: none;
    width: 20%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: fixed;
    z-index: 1000;
    font-size: 30px;
    right: 0;
    top:0;
    background: white;
    color: black;
  }

  .modal-inner {
    top: 0;
    transform: none;
    border-radius: 0;
    padding: 15px;
    height: 100%;
    overflow: scroll;

    .info-text {
      font-size: 16px;
      text-align: left;
    }

    .submit {
      width: 100%;
      height: 50px;
      line-height: 50px;
    }

      .fields {
        flex-direction: column;
      }

    .submit {
      margin-top: 15px;
    }

    .input-text {
      width: 100%;
      margin-bottom: 20px;
      height: 50px;
      line-height: 50px;
      border: 2px solid white;
    }
  }

  .next {
    top: auto;
    right: 10%;
    padding: 5px;
    bottom: 10px;

    img {
      width: 45px;
      height: 45px;
      object-fit: cover;
    }
  }

  .prev {
    top: auto;
    left: 10%;
    padding: 5px;
    bottom: 10px;
    img {
      width: 45px;
      height: 45px;
      object-fit: cover;
    }
  }
}


@media only screen and (max-width: 650px) {
  .work-items {
    max-width: 320px;
    justify-content: center;
  }
}